import BaseService, { APIResponse } from "./BaseService";
import {StruttureResponse} from "@/services/StruttureService";

export interface CampoResponse {
  id: number
  nome: string
  descrizione: string
  flex: boolean
  media: string[]
  color: string
  aperto: boolean
  terreno: tipo_superfice
  servizi_campo: servizi_campi[]
  valutazione_media: number
  prezzo: number
  pagamento_persona: boolean
  campi_sport: Array<campi_sport>
  disponibilita: Array<Disponibilita>
}

export type CampiResponse = Array<CampoResponse>

export interface Disponibilita {
  giorno_settimana:number
  ora_inizio:string
  ora_fine:string
}
export interface campi_sport{
    id :number,
    prezzo : number,
    pagamento_persona:boolean,
    durata_partita:number,
    sport :sport
    attivo: boolean
}
export interface tipo_superfice {
  id: number
  nome: number
  descrizione: number[]
}
export interface servizi_campi {
  id: number
  nome: string
  descrizione: string
}
export interface sport{
    id:number
    nome:number
    num_giocatori:number[]

}
export interface CampoRequest{
    nome: string,
    descrizione: string,
    media: string[]|null,
    tipo_superfice: number,
    servizi_campo: number[],
    color:string,
    flex:boolean,
    sport:{
      id:number
      prezzo : number,
      pagamento_persona:boolean,
      durata:number
    }[],
    disponibilita:{
      giorno_settimana:number
      ora_inizio : string,
      ora_fine:string
    }[]
}
export default class CampiService extends BaseService {
    static async ListaCampi(idStruttura: number,idSport?:number): Promise<APIResponse<CampiResponse>> {
        return await this.perform<CampiResponse>(
          {
            params:{"id_sport":idSport},
            url: "operatore/campi/"+idStruttura,
            method: "GET",
        });
    }
    static async addCampo(idStruttura: number,campo:CampoRequest): Promise<APIResponse<CampiResponse>> {
        return await this.perform<CampiResponse>({
            url: "operatore/campi/add/"+idStruttura,
            method: "post",
            body:campo
        });
    }
  static async campo(idStruttura: number,idCampo:number): Promise<APIResponse<CampiResponse>> {
    return await this.perform<CampiResponse>({
      url: "operatore/campi/info/"+idStruttura,
      method: "get",
      params:{"id_campo": idCampo}
    });
  }
  static async modificaCampo(idStruttura: number,idCampo: number,campo:CampoRequest): Promise<APIResponse<string>> {
    return await this.perform<string>({
      url: "operatore/campi/edit/"+idStruttura+"/"+idCampo,
      method: "post",
      body:campo
    });
  }
  static async modificaDisponibilita(idStruttura: number,idCampo: number,campo:any): Promise<APIResponse<string>> {
    return await this.perform<string>({
      url: "operatore/campi/editdisponibilita/"+idStruttura+"/"+idCampo,
      method: "post",
      body:campo
    });
  }
  static async eliminaCampo(idStruttura: number,idCampo: number): Promise<APIResponse<string>> {
    return await this.perform<string>({
      url: "operatore/campi/delete/"+idStruttura+"/"+idCampo,
      method: "put"
    });
  }
  static async tipoSuperfici(): Promise<APIResponse<tipo_superfice[]>> {
    return await this.perform<tipo_superfice[]>({
      url: "operatore/campi/tipoterreni",
      method: "get"
    });
  }
  static async serviziCampo(): Promise<APIResponse<servizi_campi[]>> {
    return await this.perform<servizi_campi[]>({
      url: "operatore/campi/servizi",
      method: "get"
    });
  }
  static async controlloCampi(idStruttura: number): Promise<APIResponse<boolean>> {
    return await this.perform<boolean>({
      url: "operatore/campi/campimassimi/"+idStruttura,
      method: "get"
    });
  }

  static async bloccaCampo(id_campo: number, id_struttura: number): Promise<APIResponse<string>> {
    return await this.perform<string>({
      url:"operatore/campi/disabilita/" + id_campo,
      params:{"id_struttura": id_struttura},
      method: "POST"
    })
  }
}

