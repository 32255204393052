import BaseService, {APIResponse} from "@/services/BaseService";
import {PrenotaModel} from "@/Classes/PrenotaClass";
import {PrenotazioneModel} from "@/Classes/PrenotazioneClass";
import {PrezzoBody} from "@/Classes/Prezzo";
import {Importo} from "@/Classes/Importo";

export interface Struttura {
    nome: string;
}

export interface Campo {
    nome: string;
    struttura: Struttura;
    color:string;
}

export interface Sport {
    nome: string;
}

export interface CampiSport {
    id: number;
    campo: Campo;
    sport: Sport;
}
export interface Utente {
  email: string;
  cognome: string;
  id:number;
}
export interface Prenotazione {
    data_ora: Date;
    data_fine: Date;
    utente: Utente;
    metodo_pagamento?: any;
    id: number;
    commento:string;
    importo_pagato: any;
    servizi: any[];
    campi_sport: CampiSport;
}
export interface Disponibilita{
  durata_partita:string,
  agende:Fascia[]
}

export interface Fascia {
  giorno: string;
  intervalli: Intervallo[];
}

export interface Intervallo {
  start:string;
  end:string;
  enabled:boolean
}

export interface Incassi{
  importo_totale: number,
  importo_sconto_operatore: number,
  importo_sconto: number,
  importo_promozione: number,
  importo_app: number
}

interface FiltriRicerca {
  data_inizio: Date | string
  data_fine: Date | string
  sport: number | null
  campo: number | null
}

export interface Classifica{
  id: number,
  nome: string,
  cognome: string,
  numero_prenotazioni: number,
  numero_sconti: number
}[]

export default class PrenotazioneService extends BaseService {

    static async getPrenotazione(filtro:any): Promise<APIResponse<PrenotazioneModel[]>> {
        return await this.perform<PrenotazioneModel[]>({
            url: "operatore/prenotazioni/lista",
            method: "GET",
            params:filtro
        });
    }

  static async getPrenotazioneAnnullate(filtro:any): Promise<APIResponse<PrenotazioneModel[]>> {
    return await this.perform<PrenotazioneModel[]>({
      url: "operatore/prenotazioni/annullate",
      method: "GET",
      params:filtro
    });
  }

  static async getImporto(body:PrezzoBody): Promise<APIResponse<Importo>> {
    return await this.perform<Importo>({
      url: "operatore/prenotazioni/calcolaImporto",
      method: "POST",
      body:body
    });
  }


  static async annullaPrenotazione(id:number,commento:string): Promise<APIResponse<Prenotazione[]>> {
    return await this.perform<Prenotazione[]>({
      url: "operatore/prenotazioni/annullaprenotazione",
      method: "POST",
      body:{
        "id_prenotazione":id,
        "commento":commento
      }
    });
  }

  static async Sposta(body:PrenotaModel, id_struttura: number, id_prenotazione: number): Promise<APIResponse<Prenotazione>> {
    return await this.perform<Prenotazione>({
      url: "operatore/prenotazioni/spostamento/" + id_struttura + "/" + id_prenotazione,
      method: "POST",
      body:body
    });
  }

  static async Disponibilita(query:any): Promise<APIResponse<Disponibilita>>{
    return await this.perform<Disponibilita>({
      url: "/disponibilita/avaibleday",
      params:query,
      method: "GET",
    });
  }

  static async Prenota(body:PrenotaModel): Promise<APIResponse<Prenotazione>> {
    return await this.perform<Prenotazione>({
      url: "operatore/prenotazioni/prenota",
      method: "POST",
      body:body
    });
  }

  static async RimborsoPrenotazione(id_prenotazione:number, id_struttura:number):Promise<APIResponse<PrenotazioneModel>>{
      return await this.perform<PrenotazioneModel>({
        url: "operatore/prenotazioni/rimborso/"+id_struttura,
        method: "POST",
        body: {"id_prenotazione": id_prenotazione}
      })
  }

  static async NumeroPrenotazioni(id_struttura: number, filtro: any):Promise<APIResponse<number>>{
      return await this.perform<number>({
        url:"operatore/statistiche/prenotazioni/"+id_struttura,
        params:filtro,
        method:"get"
      })
  }

  static async NumeroPrenotazioniApp(id_struttura: number, filtro: any):Promise<APIResponse<number>>{
    return await this.perform<number>({
      url:"operatore/statistiche/prenotazioniWeb/"+id_struttura,
      method:"get",
      params:filtro,
    })
  }
  static async NumeroPrenotazioniAnnullate(id_struttura: number, filtro: any):Promise<APIResponse<number>>{
    return await this.perform<number>({
      url:"operatore/statistiche/prenotazioniAnnullate/"+id_struttura,
      method:"get",
      params:filtro,
    })
  }
  static async GuadagniTotali(id_struttura: number, filtro: any):Promise<APIResponse<Incassi>>{
      return await this.perform<Incassi>({
        url: "operatore/statistiche/guadagni/"+id_struttura,
        method: "get",
        params:filtro,
      })
  }
  static async ClassificaUtenti(id_struttura: number, filtro: any):Promise<APIResponse<Classifica[]>>{
      return await this.perform<Classifica[]>({
        url: "operatore/statistiche/classifica/"+id_struttura,
        method: "get",
        params:filtro,
      })
  }
  static async prenotazioniMultiple(form: any): Promise<APIResponse<any>>{
      return await this.perform<any>({
        url:"operatore/prenotazioni/prenotazione_multipla",
        method:"post",
        body: form
      })
  }
  static async getPrenotazioniMultiple(id_struttura: number): Promise<APIResponse<PrenotazioneModel[]>>{
      return await this.perform<PrenotazioneModel[]>( {
        url:"operatore/prenotazioni/prenotazioni_multiple/"+id_struttura,
        method:"get"
      })
  }
  static async deletePrenotazioniMultiple(id_prenotazione: string): Promise<APIResponse<any>>{
      return await this.perform<any>({
        url:"operatore/prenotazioni/annullaprenotazioni_multiple",
        method:"post",
        body: {"id": id_prenotazione}
      })
  }

}
